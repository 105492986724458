import { PageLoadingBar } from '../../../app/components/PageLoadingBar';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { OrganizerRoleUtils } from '../../types';
import { useUGCAnalytics } from '../Game/UGC/utils';
import { NavItem, ScheduleTooltip } from '../Header';
import { HeaderAvatar } from '../HeaderAvatar';
import { useMyOrganizationFeatureChecker } from '../Organization/hooks/organization';
import { useUser } from '../UserContext';
import { LunaParkMenuContainer } from './LunaParkLogoMenu';

export function PublicHomeLayoutV2(props: {
  secondaryNav?: React.ReactNode;
  children: React.ReactNode;
  backgroundClassName?: string;
}) {
  const { secondaryNav } = props;

  const analytics = useUGCAnalytics();
  const featureChecker = useMyOrganizationFeatureChecker();
  const ugcEnabled = useFeatureQueryParam('game-pack-ugc');
  const showCustomGameLink =
    ugcEnabled || featureChecker.hasUnlimitedOndGameUGCAccess();
  const user = useUser();
  const isAdminOrOrgOwner = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);

  const renderNavItems = () => {
    return (
      <div className='flex-none h-full flex items-center gap-10'>
        {showCustomGameLink && (
          <NavItem
            title='My Custom Games'
            to='/custom-games'
            onClick={() => analytics.trackMyCustomGamesClicked()}
          />
        )}
        <ScheduleTooltip />
      </div>
    );
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <PageLoadingBar color='#f11946' />

      <div className='w-full flex-none h-15 bg-black text-white border-secondary border-b flex items-center gap-10 z-40 pl-10 pr-5'>
        <LunaParkMenuContainer isAdmin={isAdminOrOrgOwner} />
        {renderNavItems()}
        <HeaderAvatar />
      </div>

      {secondaryNav}

      <div
        className={`w-full flex-1 ${
          props.backgroundClassName ?? 'bg-modal'
        } text-white overflow-auto scrollbar`}
      >
        {props.children}
      </div>
    </div>
  );
}
